// Import Bootstrap functions (needed to use mixins and other functions)
@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";




$font-family-base: 'Roboto', sans-serif;  // Choose your preferred font
$font-size-base: .85rem; // Default is usually 1rem (16px), adjust as needed
$headings-font-family: $font-family-base;  // Make headings use the same font
